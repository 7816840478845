import moment from 'moment'
import numeral from 'numeral'

export default {
  methods: {
    timeFormatterTwelveHours (value) {
      if (value) {
        return moment(value).format('LT')
      }
      return null
    },

    dateTimeFormatterLong (value) {
      if (value) {
        return moment(value).format('MMMM DD, YYYY - LT')
      }
      return null
    },

    dateFormatterShort (value) {
      if (value) {
        return moment(value).format('MMM DD, YYYY')
      }
      return null
    },

    dateFormatterLong (value) {
      if (value) {
        return moment(value).format('MMMM DD, YYYY')
      }
      return null
    },

    dateFormatter (value) {
      if (value) {
        return moment(value).format('Y-MM-DD')
      }
      return null
    },

    dateTimeFormatter (value) {
      if (value) {
        return moment(value).format('Y-MM-DD HH:mm:ss')
      }
      return null
    },

    numberFormatter (value) {
      if (value) {
        return numeral(value).format('0,0.00')
      }
      return null
    }
  }
}
